var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { UserService } from "~/services/manage-service/user.service";
import { RoleService } from "~/services/manage-service/role.service";
var UserList = /** @class */ (function (_super) {
    __extends(UserList, _super);
    function UserList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectionList = [];
        _this.model = {
            id: '',
            realName: '',
            userName: '',
            status: '',
            roleId: ''
        };
        _this.dataSet = [];
        _this.deleteModel = {
            roleId: "",
            userIds: [] //用户ID集合
        };
        return _this;
    }
    UserList.prototype.close = function () {
        var addForm = this.$refs["form"];
        addForm.resetForm();
        this.$emit("close");
    };
    UserList.prototype.refresh = function (id) {
        this.model.roleId = id;
        this.refreshData();
    };
    UserList.prototype.mounted = function () {
    };
    UserList.prototype.refreshData = function () {
        var _this = this;
        this.roleService.findUserByRole(this.model, this.pageService, this.sortService, this.pageService).subscribe(function (data) {
            _this.dataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    /**
     * 批量删除对应人员的角色信息
     */
    UserList.prototype.batchRemoveRoleClick = function () {
        var _this = this;
        if (this.selectionList.length === 0) {
            this.$alert("请选择要删除角色的用户", "提示", {
                confirmButtonText: "确定"
            });
        }
        else {
            var userIds_1 = [];
            this.selectionList.forEach(function (role) {
                userIds_1.push(role.id);
            });
            this.deleteModel.roleId = this.model.roleId;
            this.deleteModel.userIds = userIds_1;
            this.roleService.deleteRoleUser(this.deleteModel).subscribe(function (data) {
                _this.$message.success("删除成功");
                _this.refreshData();
            });
        }
    };
    __decorate([
        Dependencies(SortService)
    ], UserList.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], UserList.prototype, "pageService", void 0);
    __decorate([
        Dependencies(UserService)
    ], UserList.prototype, "userService", void 0);
    __decorate([
        Dependencies(RoleService)
    ], UserList.prototype, "roleService", void 0);
    UserList = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox
            }
        })
    ], UserList);
    return UserList;
}(Vue));
export default UserList;
