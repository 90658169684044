var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { Emit, Watch, Model, Prop } from "vue-property-decorator";
var ResourceControl = /** @class */ (function (_super) {
    __extends(ResourceControl, _super);
    function ResourceControl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataSet = [];
        _this.selectionList = [];
        return _this;
    }
    ResourceControl.prototype.emitSelectedChange = function (val) { };
    ResourceControl.prototype.emitCheckTreeNode = function () { };
    ResourceControl.prototype.onCurrentTreeIdChange = function (val) {
        var _this = this;
        this.dataSet = this.controlSet.filter(function (x) { return x.parent === val; });
        this.selectionList = this.dataSet.filter(function (x) { return _this.selectedControlIds.includes(x.id); });
    };
    ResourceControl.prototype.onSelectionListChange = function (val) {
        if (val.length)
            this.emitCheckTreeNode();
        var unCheckKeys = this.dataSet.filter(function (x) { return val.findIndex(function (v) { return v.id === x.id; }) < 0; }).map(function (v) { return v.id; });
        var keySet = new Set(val.map(function (v) { return v.id; }).concat(this.selectedControlIds));
        unCheckKeys.forEach(function (v) { return keySet.delete(v); });
        this.emitSelectedChange(Array.from(keySet));
    };
    __decorate([
        Prop()
    ], ResourceControl.prototype, "currentTreeId", void 0);
    __decorate([
        Prop()
    ], ResourceControl.prototype, "controlSet", void 0);
    __decorate([
        Prop()
    ], ResourceControl.prototype, "roleId", void 0);
    __decorate([
        Model('selected-change', {
            type: Array,
            default: function () { return []; }
        })
    ], ResourceControl.prototype, "selectedControlIds", void 0);
    __decorate([
        Emit('selected-change')
    ], ResourceControl.prototype, "emitSelectedChange", null);
    __decorate([
        Emit('check-tree-node')
    ], ResourceControl.prototype, "emitCheckTreeNode", null);
    __decorate([
        Watch('currentTreeId', { immediate: true })
    ], ResourceControl.prototype, "onCurrentTreeIdChange", null);
    ResourceControl = __decorate([
        Component({
            components: {
                DataBox: DataBox
            }
        })
    ], ResourceControl);
    return ResourceControl;
}(Vue));
export default ResourceControl;
