import { render, staticRenderFns } from "./common-title.vue?vue&type=template&id=6d72bf9e&scoped=true"
import script from "./common-title.vue?vue&type=script&lang=ts"
export * from "./common-title.vue?vue&type=script&lang=ts"
import style0 from "./common-title.vue?vue&type=style&index=0&id=6d72bf9e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d72bf9e",
  null
  
)

export default component.exports