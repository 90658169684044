var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { manageService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var RoleService = /** @class */ (function (_super) {
    __extends(RoleService, _super);
    function RoleService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 新增角色
     */
    RoleService.prototype.addRole = function (data, loading) {
        return this.netService.send({
            server: manageService.roleController.addRole,
            data: data,
            loading: loading
        });
    };
    /**
     * 修改角色
     */
    RoleService.prototype.modifyRole = function (data, loading) {
        return this.netService.send({
            server: manageService.roleController.modifyRole,
            data: data,
            loading: loading
        });
    };
    /**
     * 删除角色
     */
    RoleService.prototype.deleteRole = function (roleId, loading) {
        return this.netService.send({
            server: manageService.roleController.deleteRole,
            data: {
                roleId: roleId
            },
            loading: loading
        });
    };
    /**
     * 用户分配角色
     */
    RoleService.prototype.distributeRole = function (data, loading) {
        return this.netService.send({
            server: manageService.roleController.distributeRole,
            data: data,
            loading: loading
        });
    };
    /**
     * 删除角色下用户
     */
    RoleService.prototype.deleteRoleUser = function (data, loading) {
        return this.netService.send({
            server: manageService.roleController.deleteRoleUser,
            data: data,
            loading: loading
        });
    };
    /**
     * 分配模块权限
     */
    RoleService.prototype.distributePermission = function (data, loading) {
        return this.netService.send({
            server: manageService.roleController.distributePermission,
            data: data,
            loading: loading
        });
    };
    /**
     * 复制角色
     */
    RoleService.prototype.copyRole = function (roleId, loading) {
        return this.netService.send({
            server: manageService.roleController.copyRole,
            data: {
                roleId: roleId
            },
            loading: loading
        });
    };
    /**
     * 多条件查询角色
     */
    RoleService.prototype.findAllRole = function (data, page, sort, loading) {
        return this.netService.send({
            server: manageService.roleController.findAllRole,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 多条件查询角色下用户
     */
    RoleService.prototype.findUserByRole = function (data, page, sort, loading) {
        return this.netService.send({
            server: manageService.roleController.findUserByRole,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 查询角色下所有资源
     */
    RoleService.prototype.findAllResource = function (roleId, loading) {
        return this.netService.send({
            server: manageService.roleController.findAllResource,
            data: {
                roleId: roleId
            },
            loading: loading
        });
    };
    /**
     * 查询角色下所有控件
     */
    RoleService.prototype.findAllControls = function (roleId, loading) {
        return this.netService.send({
            server: manageService.roleController.findAllControls,
            data: {
                roleId: roleId
            },
            loading: loading
        });
    };
    /**
     * 根据条件查询角色
     */
    RoleService.prototype.findRoles = function (data, page) {
        return this.netService.send({
            server: manageService.roleController.findRoles,
            data: data,
            page: page
        });
    };
    /**
     * 查询所有角色
     */
    RoleService.prototype.getAllRoles = function () {
        return this.netService.send({
            server: manageService.roleController.getAllRoles
        });
    };
    /**
     * 批量分配角色
     */
    RoleService.prototype.batchDistributeRole = function (data) {
        return this.netService.send({
            server: manageService.roleController.batchDistributeRole,
            data: data
        });
    };
    __decorate([
        Inject(NetService)
    ], RoleService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], RoleService.prototype, "addRole", null);
    __decorate([
        Debounce()
    ], RoleService.prototype, "modifyRole", null);
    __decorate([
        Debounce()
    ], RoleService.prototype, "deleteRole", null);
    __decorate([
        Debounce()
    ], RoleService.prototype, "distributeRole", null);
    __decorate([
        Debounce()
    ], RoleService.prototype, "deleteRoleUser", null);
    __decorate([
        Debounce()
    ], RoleService.prototype, "distributePermission", null);
    __decorate([
        Debounce()
    ], RoleService.prototype, "copyRole", null);
    __decorate([
        Debounce()
    ], RoleService.prototype, "findAllRole", null);
    __decorate([
        Debounce()
    ], RoleService.prototype, "findUserByRole", null);
    __decorate([
        Debounce()
    ], RoleService.prototype, "findAllResource", null);
    __decorate([
        Debounce()
    ], RoleService.prototype, "findAllControls", null);
    __decorate([
        Debounce()
    ], RoleService.prototype, "findRoles", null);
    __decorate([
        Debounce()
    ], RoleService.prototype, "getAllRoles", null);
    return RoleService;
}(Service));
export { RoleService };
